import React, {useEffect, useRef, useState} from 'react';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {Provider} from 'react-redux';
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import {createBrowserHistory} from 'history';
import {initStore} from './app/store';
import LoginRouter from './LoginRouter';
import {signActions} from './redux/actions';
import PromptMessage from './components/PromptMessage';
import LoaderHOC from './components/LoaderHOC.jsx';
import {SnackbarProvider} from 'notistack';

import ruLocale from 'date-fns/locale/ru';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

// import './styles/resetStyles.scss';
import './index.css';
import './styles/index.scss';
import {createThemeWithDefaultColors, createThemeWithGrayButtons} from '@Shared/themes/theme';
import HeaderContentContext from '@Admin/components/Header/HeaderContentContext';

const theme = createThemeWithDefaultColors();

const themeWithCustomButtons = createThemeWithGrayButtons(theme);

const browserHistory = createBrowserHistory({
    basename: `/`
});

const store = initStore({}, browserHistory);

export const App = () => {

    useEffect(() => {
        store.dispatch(signActions.checkIdentity());
    }, []);

    const [renderHeaderContent, setRenderHeaderContent] = useState(undefined);
    const headerDivRef = useRef(null);

    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: "center", vertical: "top"}}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                    <HistoryRouter history={browserHistory}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={themeWithCustomButtons}>
                                <LoaderHOC/>
                                <HeaderContentContext.Provider value={{
                                    onRenderContent: renderHeaderContent?.onRenderHeaderContent,
                                    setRenderContent: setRenderHeaderContent,
                                    headerContainerRef: headerDivRef
                                }}>
                                    <LoginRouter/>
                                </HeaderContentContext.Provider>
                                <PromptMessage/>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </HistoryRouter>
                </LocalizationProvider>
            </SnackbarProvider>
        </Provider>
    );
}
