import { isEqualByRootPrototypesOrId } from "./object";
import _ from "lodash";
import { Category } from "@Shared/features/tournament/v1/tournamentCategory/models";

export const tournamentCategoryCoreFields = [
   'id',
   'tournamentDivisionGroupId',
   'battleOrder',
   "tournamentCarpetId"
];

export const tournamentCategoryDataFields = [
   'genderId', 
   'weightMin', 
   'weightMax', 
   'weight',
   'ageMin', 
   'ageMax', 
   'rankId'
];

export const tournamentCategoryFields = [
   ...tournamentCategoryCoreFields,
   ...tournamentCategoryDataFields
];

export const isEqual = (categories) => {
   return isEqualByRootPrototypesOrId(categories);
}

export const parseApiModel = (apiModel: Category) => {
   return {
      ...(_.pick(apiModel.data, tournamentCategoryDataFields)),
      ...(_.pick(apiModel, tournamentCategoryCoreFields))
   }
}

export const convertToApiModel = (model) => {
   return {
      data: _.pick(model, tournamentCategoryDataFields),
      ...(_.pick(model, tournamentCategoryCoreFields))
   }
}

export const parseApiModels = (apiModels: Category[] | undefined) => {
   return _.isArray(apiModels)
      ? apiModels.map(apiModel => parseApiModel(apiModel))
      : apiModels;
}

export const convertToApiModels = (models) => {
   return _.isArray(models)
      ? models.map(model => convertToApiModel(model))
      : models;
}

interface CategoryShort {
   weightMin: number,
   weightMax?: number,
   genderId?: number
}

export const formatCategoryString = (categoryString: string) => {
   const categoriesArray = categoryString
      ?.replace(/[^0-9,+]/g, "")
      .split(",")
      .filter((category)=>category !== '');
   
   const categoriesWithPlus = categoriesArray.filter(c => c.indexOf('+') !== -1);
   if(categoriesWithPlus.length > 1) {
      return {
         result: [],
         error: "Лишний \"+\" вес."
      }
   }

   let categoryPlusNumber : number | undefined = undefined;
   if(categoriesWithPlus.length > 0) {
      const categoryWithPlus = categoriesWithPlus[0];

      const categoryPlusNumberToString = categoryWithPlus.replace(/[^0-9]/g, "");
      if(categoryPlusNumberToString.length === 0){
         return {
            result: [],
            error: "Неверный формат \"+\" веса."
         }
      }

      const countPlus = categoryWithPlus.replace(/[^+]/g, "").length;
      if(countPlus > 1){
         return {
            result: [],
            error: "Неверный формат \"+\" веса."
         }
      }

      const indexPlus = categoryWithPlus.indexOf('+');
      if(indexPlus != 0 && indexPlus != categoryWithPlus.length-1) {
         return {
            result: [],
            error: "Неверный формат \"+\" веса."
         }
      }

      categoryPlusNumber = Number(categoryPlusNumberToString)
   }

   const categoriesWithoutPlus = [...new Set(categoriesArray.filter(c => c.indexOf('+') === -1).sort((a, b) => parseInt(a) - parseInt(b)))];
   if(categoryPlusNumber 
         && categoriesWithoutPlus.length > 0 
         && categoryPlusNumber < Number(categoriesWithoutPlus[categoriesWithoutPlus.length-1])) {
         return {
            result: [],
            error: "Конфликт весов. \"+\" вес не является самым большим весом."
         }
   }

   return {
      result: categoryPlusNumber ? [...categoriesWithoutPlus, `${categoryPlusNumber}+`] : categoriesWithoutPlus
   }
}

 
export const addCategories = (
    categories: string[],
    tempDivisions: any,
    divisionId: number,
    groupId: number,
    genderId?: number
) => {
    if (_.isArray(categories) && categories.length > 0) {
        let newCategories: CategoryShort[] = [];
        const isToInf = categories[0].slice(-1) == '+' || categories[0].slice(1) == '+';
        newCategories.push({
            weightMin: isToInf ? parseInt(categories[0]) : 0,
            weightMax: isToInf ? undefined : parseInt(categories[0]),
            genderId: genderId,
        });
        let prev = categories[0];

        for (let index = 1; index < categories.length; index++) {
            const element = categories[index];
            const isToInf = element.slice(-1) == '+' || categories[0].slice(1) == '+';
            newCategories.push({
               weightMin: isToInf ? parseInt(element) : +prev,
               weightMax: isToInf ? undefined : parseInt(element),
               genderId: genderId,
            });   
            prev = element;
        }
        tempDivisions[divisionId].groups[groupId].categories = [
            ...tempDivisions[divisionId].groups[groupId].categories,
            ...newCategories
        ];
    }
};
