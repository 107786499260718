import { createTheme, alpha } from "@mui/material/styles"

export const createThemeWithDefaultColors = () => {
    return createTheme({  
        spacing: 10,
        palette: {
            mode: 'light',
            primary: {
                main: '#3e62a0',
            },
            primaryHeader: {
                //main: '#d0f2ff',
            },
            secondary: {
                main: `#F50057`,
            },
            success: {
                main: `#66b266`
            },
            pending: {
                main: `#B2AC64`
            },
            selected: {
                main: `#98fb98`
            },
        }
    });
}

declare module '@mui/material/styles' {
  interface Palette {
    primaryHeader: Palette['primary'];
    pending: Palette['primary'];
    selected: Palette['primary'];
  }

  interface PaletteOptions {
    primaryHeader: PaletteOptions['primary'];
    pending: PaletteOptions['primary'];
    selected: PaletteOptions['primary'];
  }
}

export const createThemeWithGrayButtons = (theme) =>{
    return createTheme(theme, {
        components: {
            MuiTabs: {
              styleOverrides: {
                indicator: {
                  backgroundColor: "#fff0"
                }
              }
            },
            MuiButton: {
              variants: [
                {
                  props: { variant: "contained", color: "grey" },
                  style: {
                    color: theme.palette.getContrastText(theme.palette.grey[300])
                  }
                },
                {
                  props: { variant: "outlined", color: "grey" },
                  style: {
                    color: theme.palette.text.primary,
                    borderColor:
                      theme.palette.mode === "light"
                        ? "rgba(0, 0, 0, 0.23)"
                        : "rgba(255, 255, 255, 0.23)",
                    "&.Mui-disabled": {
                      border: `1px solid ${theme.palette.action.disabledBackground}`
                    },
                    "&:hover": {
                      borderColor:
                        theme.palette.mode === "light"
                          ? "rgba(0, 0, 0, 0.23)"
                          : "rgba(255, 255, 255, 0.23)",
                      backgroundColor: alpha(
                        theme.palette.text.primary,
                        theme.palette.action.hoverOpacity
                      )
                    }
                  }
                },
                {
                  props: { color: "grey", variant: "text" },
                  style: {
                    color: theme.palette.text.primary,
                    "&:hover": {
                      backgroundColor: alpha(
                        theme.palette.text.primary,
                        theme.palette.action.hoverOpacity
                      )
                    }
                  }
                }
              ]
            }
          }
    })
}