import React from 'react';
import clsx from 'clsx';
import {ButtonHTMLAttributes} from 'react';
import styles from './Button.module.scss';

const getButtonVariantClassName = (buttonVariant: ButtonVariant) => {
    switch (buttonVariant) {
        case ButtonVariant.Blue:
            return styles.blue;
         
        case ButtonVariant.OutlinedBlue:
            return styles.outlinedBlue;
    
        case ButtonVariant.Green:
            return styles.green;
            
        case ButtonVariant.Outlined:
            return styles.outlined;
   
        case ButtonVariant.Red:
            return styles.red;

        default:
            return styles.blue;
    }
};


export enum ButtonVariant {
    Blue = 'Blue',
    Green = 'Green',
    Red = "Red",
    Outlined = "Outlined",
    OutlinedBlue = "OutlinedBlue",
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    title?: string;
    disabled?: boolean;
    onClick?: () => void;
    buttonVariant?: ButtonVariant;
    beforeIcon?: string | JSX.Element;
    afterIcon?: string | JSX.Element;
}

const Button = ({
                    onClick,
                    disabled,
                    title = 'Button',
                    buttonVariant = ButtonVariant.Blue,
                    className,
                    beforeIcon,
                    afterIcon,
                    type = 'button',
                }: ButtonProps) => {
    return (
        <button
            type={type}
            className={`
                ${styles.button}
                ${getButtonVariantClassName(buttonVariant)}
                ${className}
            `}
            onClick={onClick}
            disabled={disabled}
        >
            <div className={styles.content}>
                {beforeIcon && 
                    <div className={styles.beforeIcon}>
                        {beforeIcon}
                    </div>
                }
                <p className={clsx(styles.buttonText, beforeIcon && styles.hasBeforeIcon, afterIcon && styles.hasAfterIcon)}>{title}</p>
                {afterIcon && 
                    <div className={styles.afterIcon}>
                        {afterIcon}
                    </div>
                }
            </div>
        </button>
    );
};

export default Button;
