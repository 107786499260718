import React, { createContext } from "react";

export interface RenderHeaderContent{
  onRenderHeaderContent: () => React.ReactNode
}

interface HeaderContentContext{
  onRenderContent: () => React.ReactNode,
  setRenderContent: (value: RenderHeaderContent | undefined) => void
  headerContainerRef: React.RefObject<HTMLDivElement> | null
}

const HeaderContentContext = createContext<HeaderContentContext>({
  onRenderContent: () => null,
  setRenderContent: () => null,
  headerContainerRef: null,
});
  
export default HeaderContentContext;