import {
   convertToApiModels as convertDivisionsToApiModels
} from "../../Shared/helpers/tournamentDivision";

export const tournamentFields = [
   'id',
   'name', 
   'description', 
   'contactInfo',
   'countryId',
   'regionLocalId',
   'city',
   'street',
   'headReferee',
   'headSecretary',
   'organiser',
   'assistants',
   'limitOfParticipants',
   'start', 
   'end', 
   'statusId',
   'links',
   'tournamentLogo',
   'tournamentReportLogo'
];

export const convertToApiModel = (model) => {
   return {
      divisions: convertDivisionsToApiModels(model.divisions),
      ...(_.pick(model, tournamentFields)),
   }
}
export const convertToApiModels = (models) => {
   return _.isArray(models)
      ? models.map(model => convertToApiModel(model))
      : models;
}