import React, {Suspense, useMemo} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Loader from "../Shared/components/Loader";
import {getAccessWithPermission, permissions} from '@Shared/helpers/permissionsHelper';
import settingsGlobal from '@Shared/settings';
import {useCheckV2Query} from '@Shared/features/auth/v2/authApiSliceV2';
import {useAccountPermissionsGetQuery} from '@Shared/features/accounts/accountsApiSlice';
import LoginContainer from './views/LoginContainer/LoginContainer';

const AppTabs = React.lazy(() => import(/* webpackChunkName: "apptabs" */'@Admin/views/AppTabs/AppTabs'));
const PublicContainer = React.lazy(() => import(/* webpackChunkName: "public" */'~/Admin/views/PublicContainer'));

const RequireUnAuth = (props) => {
    const {children, redirectTo, isAuthorized} = props;
    return isAuthorized ? <Navigate to={redirectTo} replace/> : children;
}

const RequireAuth = (props) => {
    const {children, redirectTo, isAuthorized} = props;
    return isAuthorized ? children : <Navigate to={redirectTo} replace/>;
}

const RequirePermission = (props) => {
    const {children, redirectTo, hasAdminPortalPermission, isAuthorized} = props;
    if (hasAdminPortalPermission || isAuthorized === false) {
        return children;
    }

    window.location.href = redirectTo;
}

const LoginElement = (props) => (
    <RequireUnAuth isAuthorized={props.isAuthorized} redirectTo="/">
        <LoginContainer/>
    </RequireUnAuth>
);

const RouterComponent = () => {

    const {data: isAuthorized} = useCheckV2Query();

    const {data: accountPermissionsData, isLoading, isError} = useAccountPermissionsGetQuery();
    const accountPermissions = useMemo(() => {
        if (isLoading || isError) {
            return undefined;
        }

        return accountPermissionsData?.result || []
    }, [isLoading]);

    if (isAuthorized !== Boolean(isAuthorized)) {
        return <Loader/>;
    }

    if (isAuthorized && accountPermissions === undefined) {
        return <Loader/>;
    }
    const hasAdminPortalPermission = getAccessWithPermission(accountPermissions?.map(ap => ap.permissionId), [permissions.adminPortalAccess]);

    return (
        <Suspense fallback={<Loader/>}>
            <Routes>
                <Route path="/public/:publicKey" element={<PublicContainer/>}/>
                <Route path="login/*">
                    <Route path=":action">
                        <Route path=':recoveryCode' element={<LoginElement isAuthorized={isAuthorized}/>}/>
                        <Route path='' element={<LoginElement isAuthorized={isAuthorized}/>}/>
                    </Route>
                    <Route path='' element={<LoginElement isAuthorized={isAuthorized}/>}/>
                </Route>
                <Route path='/*' element={
                    <RequireAuth isAuthorized={isAuthorized} redirectTo="/login">
                        <RequirePermission hasAdminPortalPermission={hasAdminPortalPermission}
                                            isAuthorized={isAuthorized} redirectTo={settingsGlobal.publicPage}>
                            <AppTabs/>
                        </RequirePermission>
                    </RequireAuth>
                }/>
            </Routes>
        </Suspense>
    )

}


export default RouterComponent;